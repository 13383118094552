import React, { useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import OnlyAuthorized from "@auth/OnlyAuthorized"

import UserForm from "./_user_form"
import ApiUserForm from "./_api_user_form"

const UsersPage = () => {
  const [type, setType] = useState(0)
  return (
    <>
      <SEO title={"Add new user"} />
      <Layout title={"Add new user"}>
        <OnlyAuthorized permission={"manage:space-providers"}>
          <div className="mb-4">
            <button
              className={`btn ${type === 0 ? "btn-primary" : "btn-light"}`}
              onClick={() => setType(0)}
            >
              Space Operators
            </button>
            <button
              className={`btn ${type === 1 ? "btn-primary" : "btn-light"}`}
              onClick={() => setType(1)}
            >
              API Users
            </button>
          </div>
        </OnlyAuthorized>
        {type === 0 && <UserForm />}
        {type === 1 && <ApiUserForm />}
      </Layout>
    </>
  )
}

export default UsersPage
