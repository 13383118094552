import React, { useState } from "react"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { toast } from "react-toastify"
import { useAuth } from "@auth/useAuth"
import { API_USER } from "./_constants"

const ApiUserForm = ({ buttonLabel = "Add", existingUser = {} }) => {
  const { makeRequest } = useAuth() || {}

  const [userData, setUserData] = useState({
    name: existingUser.name || "",
    email: existingUser.email || "",
    user_type: API_USER,
  })

  const setValue = (name, value) => {
    setUserData({ ...userData, [name]: value })
  }

  const handleSubmit = () => {
    const url_slug = buttonLabel === "Add" ? "add" : "update"
    const { name, email, user_type } = userData
    if (!name || !email) return
    makeRequest(`users/${url_slug}`, {
      name,
      email,
      user_type,
    }).then((res) => {
      if (res.user) {
        // success. Display the message and go to space-operators
        toast.success(
          `User was ${
            buttonLabel === "Add" ? "created" : "updated"
          } successfully.`
        )
      } else {
        if (res.user && res.user.detail) {
          toast.error("Failed: " + res.user.detail)
        }
      }
    })
  }

  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label>
              User's Name
              {!userData.name && userData.contact_name
                ? ` (Contact name: ${userData.contact_name})`
                : ""}
            </Label>
            <Input
              type="text"
              disabled={existingUser && existingUser.name}
              placeholder="Full Name"
              value={userData.name}
              onChange={(e) => setValue("name", e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>User's Email</Label>
            <Input
              type="email"
              placeholder="Email"
              disabled={existingUser && existingUser.email}
              value={userData.email}
              onChange={(e) => setValue("email", e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Button
              color={"success"}
              onClick={handleSubmit}
              disabled={!userData.name || !userData.email}
            >
              {buttonLabel}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default ApiUserForm
